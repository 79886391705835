<template>
  <validation-observer ref="text-values-observer">
    <b-row>
      <b-col md="6" class="mb-2">
        <label :for="'i-current-value-' + request.id">
          {{ $t('Current value') }}
        </label>
        <validation-provider
          v-slot="{ errors }"
          name="oldValue"
          rules="required"
          mode="lazy"
        >
          <b-form-textarea
            :id="'i-current-value-' + request.id"
            rows="5"
            no-resize
            v-model="oldValue"
            disabled
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="6" class="mb-2">
        <label :for="'i-new-value-' + request.id">
          {{ $t('New value') }}
        </label>
        <validation-provider
          v-slot="{ errors }"
          name="newValue"
          rules="required"
          mode="lazy"
        >
          <b-form-textarea
            :id="'i-new-value-' + request.id"
            rows="5"
            no-resize
            v-model="newValue"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormGroup,
  BFormTextarea,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@mentoring-platform-validations";

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormGroup,
    BFormTextarea,
    BRow,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newValue: null,
      oldValue: null
    };
  },
  created() {
    this.oldValue = this.request.old_value;
    this.newValue = this.request.new_value;
  },
  methods: {
    isValid() {
      return this.$refs['text-values-observer'].validate().then((success) => success)
    },
    collectData() {
      return {
        new_value: this.newValue
      }
    }
  }
};
</script>