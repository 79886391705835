<template>
  <div>
    <page-header-admin class="mb-2" :title="$t('Pending edit requests')" />
    <b-row>
      <b-col>
        <b-card v-if="mentor">
          <b-row>
            <b-col md="2" class="mb-1">
              <div class="d-flex justify-content-start">
                <b-avatar
                  class="grayscale-avatar"
                  :src="mentor.user.photo_full_path"
                  :text="mentorFullname"
                  size="104px"
                />
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-column">
                <h4 class="mb-0">
                  {{ mentorFullname }}
                </h4>
                <div class="mb-2">
                  <span class="card-text">{{ $t('Registration date') }}</span>:
                  <span class="card-text">{{ mentor.created_at | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit'  }) }}</span>
                </div>
                <div>
                  <b-button variant="primary" class="mr-1 mb-1" :to="{ name: 'admin-mentor-page', params: { id: this.mentor.id } }">
                    {{ $t('Mentor page') }}
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <pending-edit-request-item
      v-for="(request) in pendingEditRequests"
      :key="request.id"
      :request="request"
      :mentor="mentor"
      class="mb-2"
    />
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BRow
} from 'bootstrap-vue'
import { usePendingEditRequest, useMentorRepository, useMentorHelpers } from '@mentoring-platform/composables'
import PendingEditRequestItem from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestItem.vue'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'
import { formatGreekDate } from '@mentoring-platform/utils/filters'

export default {
  components: {
    BAvatar,
    BButton,
    BCard,
    BCol,
    BRow,
    PageHeaderAdmin,
    PendingEditRequestItem
  },
  filters: {
    formatGreekDate
  },
  data() {
    return {
      mentor: null
    }
  },
  computed: {
    mentorFullname() {
      if(this.mentor) {
        return this.getMentorVisibleFullName(this.mentor)
      }
      return null
    }
  },
  async created() {
    this.mentor = await this.getSpecificMentor(this.$route.params.mentor_id)
    this.getPendingEditRequestsOfMentor(this.$route.params.mentor_id)
  },
  beforeMount() {
    this.$bus.$on('pending-edit-requests-list-of-mentor-needs-refresh', this.refresh)
  },
  beforeDestroy() {
    this.$bus.$off('pending-edit-requests-list-of-mentor-needs-refresh', this.refresh)
  },
  methods: {
    async refresh() {
      await this.refreshPendingEditRequestsOfMentor(this.$route.params.mentor_id)
      if(this.pendingEditRequests.length == 0) {
        this.$router.push({ name: 'admin-pending-edit-requests' })
      }
    }
  },
  setup() {
    const { getMentorVisibleFullName } = useMentorHelpers()
    const { pendingEditRequests, getPendingEditRequestsOfMentor, refreshPendingEditRequestsOfMentor } = usePendingEditRequest()
    const { getSpecificMentor } = useMentorRepository()

    return {
      pendingEditRequests,
      getMentorVisibleFullName,
      getPendingEditRequestsOfMentor,
      refreshPendingEditRequestsOfMentor,
      getSpecificMentor
    }
  }
}
</script>