<template>
  <validation-observer ref="country-values-observer">
    <b-row>
      <b-col md="6" class="mb-2">
        <b-form-group>
          <label for="i-old-country-value">
            {{ $t("Current value") }}
          </label>
          <validation-provider
            v-slot="{ errors }"
            name="oldCountry"
            rules="required"
            mode="lazy"
          >
            <b-form-select
              id="i-old-country-value"
              v-model="oldValue"
              :options="countryOptions"
              size="lg"
              disabled
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-form-group>
          <label for="i-new-country-value">
            {{ $t("New value") }}
          </label>
          <validation-provider
            v-slot="{ errors }"
            name="newCountry"
            rules="required"
            mode="lazy"
          >
            <b-form-select
              id="i-new-country-value"
              v-model="newValue"
              :options="countryOptions"
              size="lg"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@mentoring-platform-validations";
import { useCountryRepository } from '@mentoring-platform/composables'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BRow,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newValue: null,
      oldValue: null
    };
  },
  created() {
    this.oldValue = this.request.old_value;
    this.newValue = this.request.new_value;
  },
  methods: {
    isValid() {
      return this.$refs['country-values-observer'].validate().then((success) => success);
    },
    collectData() {
      return {
        new_value: this.newValue
      }
    }
  },
  setup() {
    const { getCountries, countryOptions, countryIdOfGreece } = useCountryRepository()
    getCountries()

    return {
      countryOptions,
      countryIdOfGreece
    }
  }
};
</script>