<template>
  <b-row>
    <b-col md="6" class="mb-2">
      <b-form-group>
        <label>
          {{ $t('Current value') }}
        </label>
        <div>
          <b-img :src="oldValue" fluid />
        </div>
      </b-form-group>
    </b-col>
    <b-col md="6" class="mb-2">
      <b-form-group>
        <label>
          {{ $t('New value') }}
        </label>
        <div>
          <b-img :src="newValue" fluid />
        </div>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BImg,
  BRow,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BImg,
    BRow
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newValue: null,
      oldValue: null
    };
  },
  created() {
    this.oldValue = this.request.old_value;
    this.newValue = this.request.new_value;
  },
  methods: {
    isValid() {
      return true
    },
    collectData() {
      return {
        new_value: this.newValue
      }
    }
  }
};
</script>