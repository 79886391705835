<template>
  <validation-observer ref="birthdate-values-observer">
    <b-row>
      <b-col md="6" class="mb-2">
        <b-form-group>
          <label :for="'i-current-value-' + request.id">
            {{ $t('Current value') }}
          </label>
          <validation-provider
            v-slot="{ errors }"
            name="birthdate"
            rules="required"
            mode="lazy"
          >
            <cleave
              :id="'i-current-value-' + request.id"
              v-model="oldValue"
              class="form-control cleave-datepicker"
              :raw="false"
              :options="cleaveOptions.date"
              placeholder="DD-MM-YYYY"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-form-group>
          <label :for="'i-new-value-' + request.id">
            {{ $t('New value') }}
          </label>
          <validation-provider
            v-slot="{ errors }"
            name="birthdate"
            rules="required"
            mode="lazy"
          >
            <cleave
              :id="'i-new-value-' + request.id"
              v-model="newValue"
              class="form-control cleave-datepicker"
              :raw="false"
              :options="cleaveOptions.date"
              placeholder="DD-MM-YYYY"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCardText,
  BCol,
  BFormGroup,
  BRow
} from "bootstrap-vue";
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCardText,
    BCol,
    BFormGroup,
    BRow,
    Cleave,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newValue: [],
      oldValue: [],
      cleaveOptions: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['d', 'm', 'Y'],
        },
      }
    };
  },
  created() {
    this.oldValue = this.request.old_value;
    this.newValue = this.request.new_value;
  },
  methods: {
    isValid() {
      return this.$refs['birthdate-values-observer'].validate().then((success) => success)
    },
    collectData() {
      return {
        new_value: this.newValue
      }
    }
  }
};
</script>