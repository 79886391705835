<template>
  <b-row>
    <b-col md="6" class="mb-2">
      <label>
        {{ $t('Current value') }}
      </label>
      <b-list-group>
        <b-list-group-item v-for="(item, index) in oldValue" :key="request.id + '_old_item_' + index">
          {{ item }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col md="6" class="mb-2">
      <label>
        {{ $t('New value') }}
      </label>
      <b-list-group>
        <b-list-group-item v-for="(item, index) in newValue" :key="request.id + '_new_item_' + index">
          {{ item }}
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCardText,
  BCol,
  BListGroup,
  BListGroupItem,
  BRow
} from "bootstrap-vue";

export default {
  components: {
    BCardText,
    BCol,
    BListGroup,
    BListGroupItem,
    BRow
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newValue: [],
      oldValue: []
    };
  },
  created() {
    this.oldValue = this.request.old_value;
    this.newValue = this.request.new_value;
  },
  methods: {
    isValid() {
      return true;
    },
    collectData() {
      return {
        new_value: this.newValue
      }
    }
  }
};
</script>