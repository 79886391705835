<template>
  <validation-observer ref="county-values-observer">
    <b-row>
      <b-col md="6" class="mb-2">
        <b-form-group>
          <label for="i-old-county-value">
            {{ $t("Current value") }}
          </label>
          <b-form-select
            id="i-old-county-value"
            v-model="oldValue"
            :options="countyOptions"
            size="lg"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-form-group>
          <label for="i-new-county-value">
            {{ $t("New value") }}
          </label>
          <b-form-select
            id="i-new-county-value"
            v-model="newValue"
            :options="countyOptions"
            size="lg"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@mentoring-platform-validations";
import { useCountyRepository } from '@mentoring-platform/composables'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BRow,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newValue: null,
      oldValue: null
    };
  },
  created() {
    this.oldValue = this.request.old_value;
    this.newValue = this.request.new_value;
  },
  methods: {
    isValid() {
      return this.$refs['county-values-observer'].validate().then((success) => success);
    },
    collectData() {
      return {
        new_value: this.newValue
      }
    }
  },
  setup() {
    const { getCounties, countyOptions } = useCountyRepository()
    getCounties()

    return {
      countyOptions
    }
  }
};
</script>