<template>
  <b-card :title="title">
    <validation-observer ref="pending-edit-request-observer">
      <pending-edit-request-country-values
        v-if="request.field_name == 'country_id'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-county-values
        v-else-if="request.field_name == 'county_id'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-field-of-support-values
        v-else-if="request.field_name == 'fields_of_support'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-work-status-values
        v-else-if="request.field_name == 'work_status_id'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-photo-values
        v-else-if="request.field_name == 'photo'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-status-values
        v-else-if="request.field_name == 'status'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-testimonials-visibility-values
        v-else-if="request.field_name == 'testimonials_are_visible'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-birthdate-values
        v-else-if="request.field_name == 'birthdate'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-experience-values
        v-else-if="request.field_name == 'professional_experience' || request.field_name == 'mentoring_experience'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-word-values
        v-else-if="request.type == 'word'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-text-values
        v-else-if="request.type == 'text'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-list-values
        v-else-if="request.type == 'list'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-mentoring-languages-values
        v-else-if="request.type == 'mentoring_languages'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <pending-edit-request-mentoring-language-item-values
        v-else-if="request.type == 'mentoring_language_item'"
        ref="request-values"
        class="border-bottom mb-2"
        :request="request"
      />
      <b-row>
        <b-col md="6" class="mb-2">
          <validation-provider
            v-slot="{ errors }"
            name="message"
            :rules="'required_if:selectedStatus,' + pendingEditRequestStatuses.PENDING_EDIT_REQUEST_STATUS_REJECTED + '|min:3|max:1400'"
            mode="lazy"
          >
            <label for="i-message">
              {{ $t('Message') }}
            </label>
            <b-form-textarea
              id="i-message"
              rows="5"
              v-model="message"
              no-resize
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('Approve pending edit request?')">
            <validation-provider
              v-slot="{ errors }"
              name="selectedStatus"
              rules="required"
              mode="lazy"
            >
              <b-form-radio-group
                ref="selectedStatus"
                v-model="selectedStatus"
                :options="options"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button variant="primary" @click="saveAndSendMessage">
            {{ $t('Save and send message') }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BRow
} from 'bootstrap-vue'
import PendingEditRequestBirthdateValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestBirthdateValues.vue'
import PendingEditRequestCountryValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestCountryValues.vue'
import PendingEditRequestCountyValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestCountyValues.vue'
import PendingEditRequestExperienceValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestExperienceValues.vue'
import PendingEditRequestFieldOfSupportValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestFieldOfSupportValues.vue'
import PendingEditRequestListValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestListValues.vue'
import PendingEditRequestPhotoValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestPhotoValues.vue'
import PendingEditRequestWordValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestWordValues.vue'
import PendingEditRequestWorkStatusValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestWorkStatusValues.vue'
import PendingEditRequestStatusValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestStatusValues.vue'
import PendingEditRequestTestimonialsVisibilityValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestTestimonialsVisibilityValues.vue'
import PendingEditRequestTextValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestTextValues.vue'
import PendingEditRequestMentoringLanguagesValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestMentoringLanguagesValues.vue'
import PendingEditRequestMentoringLanguageItemValues from '@mentoring-platform/views/components/blocks/PendingEditRequests/PendingEditRequestMentoringLanguageItemValues.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, required_if, min, max } from '@mentoring-platform-validations'
import { pendingEditRequestStatuses } from '@mentoring-platform/constants'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    BFormTextarea,
    BRow,
    PendingEditRequestBirthdateValues,
    PendingEditRequestCountryValues,
    PendingEditRequestCountyValues,
    PendingEditRequestExperienceValues,
    PendingEditRequestFieldOfSupportValues,
    PendingEditRequestMentoringLanguageItemValues,
    PendingEditRequestMentoringLanguagesValues,
    PendingEditRequestListValues,
    PendingEditRequestPhotoValues,
    PendingEditRequestStatusValues,
    PendingEditRequestTestimonialsVisibilityValues,
    PendingEditRequestTextValues,
    PendingEditRequestWordValues,
    PendingEditRequestWorkStatusValues,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedStatus: null,
      message: null,
      options: [
        { text: this.$t('Approve pending edit request'), value: pendingEditRequestStatuses.PENDING_EDIT_REQUEST_STATUS_APPROVED },
        { text: this.$t('Reject pending edit request'), value: pendingEditRequestStatuses.PENDING_EDIT_REQUEST_STATUS_REJECTED }
      ],
      pendingEditRequestStatuses
    }
  },
  computed: {
    title() {
      switch(this.request.field_name) {
        case 'photo':
          return this.$t('Photo');
        case 'name':
          return this.$t('Name');
        case 'surname':
          return this.$t('Surname');
        case 'email':
          return this.$t('Email');
        case 'phone':
          return this.$t('Phone');
        case 'country_id':
          return this.$t('Country');
        case 'county_id':
          return this.$t('County');
        case 'birthdate':
          return this.$t('Birthdate');
        case 'work_status_id':
          return this.$t('Work status');
        case 'work_status_free_text':
          return this.$t('Work status');
        case 'professional_title':
          return this.$t('ProfessionalTitle');
        case 'professional_experience':
          return this.$t('ProfessionalExperience');
        case 'mentoring_experience':
          return this.$t('MentoringExperience');
        case 'reason_for_mentoring':
          return this.$t('MentoringReason');
        case 'mentee_wishlist':
          return this.$t('MenteeWishlist');
        case 'fields_of_support':
          return this.$t('MentorRegistrationFieldOfSupport');
        case 'mentor_description':
          return this.$t('Mentor description');
        case 'testimonials_are_visible':
          return this.$t('Do you want your testimonials to be visible on your public profile?');
        case 'status':
          return this.$t('Status');
        case 'mentor_links':
          return this.$t('With which link do you want your profile related to?');
        case 'industries':
          return this.$t('Industry');
        case 'mentoring_languages':
          return this.$t('Mentoring languages');
        default:
          return ''
      }
    }
  },
  methods: {
    isValid() {
      return this.$refs['pending-edit-request-observer'].validate().then(success => success)
    },
    async saveAndSendMessage() {
      const isValid = await Promise.all([
        this.isValid(),
        this.$refs['request-values'].isValid()
      ]).then(results => !results.includes(false))
      if(isValid) {
        const payload = {
          id: this.request.id,
          status: this.selectedStatus,
          message: this.message,
          ...this.$refs['request-values'].collectData()
        }
        this.$store.dispatch('mentor/handlePendingEditRequest', payload)
          .then(response => {
            this.$bus.$emit('pending-edit-requests-list-of-mentor-needs-refresh')
            if(this.selectedStatus == pendingEditRequestStatuses.PENDING_EDIT_REQUEST_STATUS_APPROVED) {
              this.showSuccess(this.$t('Pending edit request approved'))
            }
            else if(this.selectedStatus == pendingEditRequestStatuses.PENDING_EDIT_REQUEST_STATUS_REJECTED) {
              this.showSuccess(this.$t('Pending edit request rejected'))
            }
          })
          .catch(error => {
            this.showError(error)
          })
      }
    },
    showSuccess(message) {
      this.$root.$bvToast.toast(message, {
        title: this.$t('Confirmation'),
        variant: 'success',
        solid: true,
      })
    },
    showError(error) {
      this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
        title: this.$t('Caution'),
        variant: 'danger',
        solid: true,
      })
    }
  }
}
</script>