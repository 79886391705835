<template>
  <b-row>
    <b-col md="6" class="mb-2">
      <b-form-group>
        <label :for="'i-current-value-' + request.id">
          {{ $t("Current value") }}
        </label>
        <b-form-checkbox
          :id="'i-current-value-' + request.id"
          class="custom-control-danger mb-2"
          name="check-button"
          v-model="oldValue"
          switch
          disabled
        >
          {{ oldValueStatusText }}
        </b-form-checkbox>
      </b-form-group>
    </b-col>
    <b-col md="6" class="mb-2">
      <b-form-group>
        <label :for="'i-new-value-' + request.id">
          {{ $t("New value") }}
        </label>
        <b-form-checkbox
          :id="'i-new-value-' + request.id"
          class="custom-control-danger mb-2"
          name="check-button"
          v-model="newValue"
          switch
        >
          {{ newValueStatusText }}
        </b-form-checkbox>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BRow,
} from "bootstrap-vue";
import { mentorStatuses } from '@mentoring-platform/constants'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BRow
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newValue: null,
      oldValue: null
    };
  },
  computed: {
    oldValueStatusText() {
      return !this.oldValue ? this.$t('Active') : this.$t('Inactive')
    },
    newValueStatusText() {
      return !this.newValue ? this.$t('Active') : this.$t('Inactive')
    }
  },
  created() {
    this.oldValue = parseInt(this.request.old_value) === mentorStatuses.MENTOR_STATUS_INACTIVE
    this.newValue = parseInt(this.request.new_value) === mentorStatuses.MENTOR_STATUS_INACTIVE
  },
  methods: {
    isValid() {
      return true;
    },
    collectData() {
      return {
        new_value: this.newValue
      }
    }
  }
};
</script>