<template>
  <validation-observer ref="field-of-support-values-observer">
    <b-row>
      <b-col md="6" class="mb-2">
        <b-form-group>
          <label for="i-old-field-of-support-value">
            {{ $t("Current value") }}
          </label>
          <validation-provider
            v-slot="{ errors }"
            name="oldFieldOfSupport"
            rules="required"
            mode="lazy"
          >
            <v-select
              v-model="oldValue"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :placeholder="$t('Choose')"
              label="text"
              :options="fieldOfSupportOptions"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6" class="mb-2">
        <b-form-group>
          <label for="i-new-field-of-support-value">
            {{ $t("New value") }}
          </label>
          <validation-provider
            v-slot="{ errors }"
            name="newFieldOfSupport"
            rules="required"
            mode="lazy"
          >
            <v-select
              v-model="newValue"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :placeholder="$t('Choose')"
              label="text"
              :options="fieldOfSupportOptions"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormInput,
  BFormGroup,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@mentoring-platform-validations";
import { useFieldOfSupportRepository } from '@mentoring-platform/composables'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BFormInput,
    BFormGroup,
    BRow,
    ValidationObserver,
    ValidationProvider,
    vSelect
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newValue: [],
      oldValue: []
    };
  },
  created() {
    this.oldValue = this.request.old_value;
    this.newValue = this.request.new_value;
  },
  methods: {
    isValid() {
      return this.$refs['field-of-support-values-observer'].validate().then((success) => success);
    },
    collectData() {
      return {
        new_value: this.newValue
      }
    }
  },
  setup() {
    const { getFieldsOfSupport, fieldOfSupportOptions } = useFieldOfSupportRepository()
    getFieldsOfSupport()

    return {
      fieldOfSupportOptions
    }
  }
};
</script>