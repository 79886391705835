<template>
  <b-row>
    <b-col
      v-if="language"
      cols="12"
      class="mb-2"
    >
      {{ getSpecificLocale(language).locale }}
      <b-img
        :src="getSpecificLocale(language).img"
        width="22"
        height="14"
      />
    </b-col>
    <b-col
      md="6"
      class="mb-2"
    >
      <label>
        {{ $t('Current value') }}
      </label>
      <template v-if="oldValue">
        <b-list-group>
          <b-list-group-item>
            {{ oldValue[language] }}
          </b-list-group-item>
        </b-list-group>
      </template>
    </b-col>
    <b-col
      md="6"
      class="mb-2"
    >
      <label>
        {{ $t('New value') }}
      </label>
      <template v-if="newValue">
        <b-list-group>
          <b-list-group-item>
            {{ newValue[language] }}
          </b-list-group-item>
        </b-list-group>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BImg,
  BListGroup,
  BListGroupItem,
  BRow
} from 'bootstrap-vue'
import { useLocales } from '@mentoring-platform/composables'

export default {
  components: {
    BCol,
    BImg,
    BListGroup,
    BListGroupItem,
    BRow
  },
  props: {
    request: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      newValue: null,
      oldValue: null,
    }
  },
  computed: {
    language() {
      if (this.newValue) {
        return Object.keys(this.newValue)[0]
      }
      return null
    }
  },
  created() {
    this.oldValue = JSON.parse(this.request.old_value)
    this.newValue = JSON.parse(this.request.new_value)
  },
  methods: {
    isValid() {
      return true
    },
    collectData() {
      return {
        new_value: this.newValue
      }
    }
  },
  setup() {
    const { locales, getSpecificLocale } = useLocales()

    return {
      locales,
      getSpecificLocale
    }
  }
}
</script>
